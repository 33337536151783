import { WelcomePage } from "../../pages";
import userRoutesMap from "../../routeControl/index";

export default function route() {
  return [
    {
      path: userRoutesMap.LANDING_PAGE.path,
      name: "Login",
      key: userRoutesMap.LANDING_PAGE.path,
      private: false,
      common: false,
      belongsToHeader: false,
      belongsToFooter: false,
      element: <WelcomePage />,
    },
  ];
}
