export const validation = {
  firstName: "Please enter your first name",
  lastName: "Please enter your last name",
  email: "Please enter your work email",
  validEmail: "Please enter valid email address",
  workEmail: "Please enter only work email",
  subject: "Please enter your subject",
  message: "Please write your message",
  phoneNumber: "Please enter your Phone number",
  contactNumber: "Please enter your Contact number",
  countryCode: "Please select country code",
  contactNumberLength: "Contact number should be 13",
  firstNameLength: "First name should be 3 to 26 number",
  lastNameLength: "Last name should be 3 to 26 number",
  subjectLength: "Subject should be 6 to 100 characters",
  messageLength: "Message should be 6 to 200 characters",
  phoneNumberLength: "Phone number should be 6 to 12 number",
  companyUrl: "Please enter your company website",
  validUrl: "Please enter valid website url",
  validMobileNumber: "Please enter valid mobile number",
};
