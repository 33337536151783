export const text = {
  sno: "S.No.",
  status: "Status",
  noRecordFound: "No Record Found",
  action: "Action",
  addNew: "Add New",
  viewAll: "View All",
  filter: "Filter",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  add: "Add",
  edit: "Edit",
  update: "Update",
  delete: "Delete",
  areYouSure: "Are you sure",
  logout: "Logout",
  reset: "Reset",
  activate: "Activate",
  deactivate: "Deactivate",
  date: "Date",
  to: "To",
  csvExports: "Export CSV",
  activateUser: "you want to activate this user?",
  deactivateUser: "you want to deactivate this user?",
  send: "Send",
  wantToLogout: "Are you sure want to logout?",
  view: "View",
  agreement: "Agreement",
  privacy: "Privacy",
  noRecord: "no record found",
  otpValid: "  Please enter valid otp",
  name: "Name",
  email: "Email",
  phone: "Phone",
  description: "Description",
  accept: "Accept",
  reject: "Reject",
  activeMessage: "you want to activate this plan?",
  deActiveMessage: "you want to deactivate this plan?",
  documentActiveMessage: "you want to activate this document type?",
  documentDeactivateMessage: "you want to deactivate this document type?",
  signOut: "Sign Out",
  contactUs: "Contact Us",
  billingSubscription: "Billing & Subscription",
  myProfile: "My Profile",
  cookies: "Cookies",
};
