export const text = {
  name: "Name",
  email: "Email",
  phone: "Phone",
  country: "Country",
  age: "Age",
  gender: "Gender",
  language: "Preferred Language",
  type: "Plan Type",
  created: "Created On",
  lastLogin: "Last Login",
  title: "Users",
  subscriberType: "Subscriber Type",
  additional: "Additional",
  sendNotification: "Send Notification",
  notificationMessage: "Notification Message",
  userManageTitle: "User Management",
};
