export const text = {
  metaTitle: "Home",
  firstNamePlaceholder: "Enter your first name",
  lastNamePlaceholder: "Enter your last name",
  emailPlaceholder: "Enter your work email",
  companyUrlPlaceholder: "Enter your company website",
  phoneNumberPlaceholder: "Enter your phone number in (888)888-8888",
  subjectPlaceholder: "Enter your subject",
  messagePlaceholder: "Enter your message",
  send: "Submit Now",
  mobilePlaceholder: "Enter your mobile no.",
};
