import { createSlice } from "@reduxjs/toolkit";
// import {
//   CommonServices,
//   TranslateServices,
//   UserProfileServices,
// } from "../../services";
import { getUniqueListBy } from "../../utils";
import logger from "../../utils/logger";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileData: {},
    languageList: [],
    countryList: [],
    cmsList: [],
  },
  reducers: {
    profileAction: (state, action) => {
      return (state = {
        ...state,
        profileData: { ...action.payload },
      });
    },
    languageAction: (state, action) => {
      return (state = {
        ...state,
        languageList: [...action.payload],
      });
    },
    countryAction: (state, action) => {
      return (state = {
        ...state,
        countryList: [...action.payload],
      });
    },
    cmsAction: (state, action) => {
      return (state = {
        ...state,
        cmsList: [...action.payload],
      });
    },
  },
});

export const { profileAction, languageAction, countryAction, cmsAction } =
  profileSlice.actions;

export const getProfile = () => async (dispatch) => {
  try {
    let res;
    // = await UserProfileServices.getDetailsService();
    const { data, success } = res;
    let resData = data;
    resData.country_code = `+${data.country_code}`;

    if (success === 1) {
      dispatch(profileAction(resData));
    }
  } catch (error) {
    logger(error);
  }
};

export const getLanguageList = () => async (dispatch) => {
  try {
    // let queryParams = { platform: "api" };
    let res;
    // = await TranslateServices.languageListingService
    // ({
    //   queryParams,
    // });
    const { result } = res;
    if (result?.length > 0) {
      let data = getUniqueListBy(result, "code_alpha_1");
      dispatch(languageAction(data));
    }
  } catch (error) {
    logger(error);
  }
};

export const getCountryList = () => async (dispatch) => {
  try {
    let res;
    // = await CommonServices.countries();
    const { data, success } = res;
    if (success === 1) {
      dispatch(countryAction(data.rows));
    }
  } catch (error) {
    logger(error);
  }
};
export const getCmsList = () => async (dispatch) => {
  try {
    let res;

    // = await CommonServices.getAllCmsService();
    const { data, success } = res;
    if (success === 1) {
      dispatch(cmsAction(data));
    }
  } catch (error) {
    logger(error);
  }
};
export const updateProfile = (data) => async (dispatch) => {
  try {
    dispatch(profileAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updateLanguageList = (data) => async (dispatch) => {
  try {
    dispatch(languageAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updateCountryList = (data) => async (dispatch) => {
  try {
    dispatch(countryAction(data));
  } catch (error) {
    logger(error);
  }
};
export const updateCmsList = (data) => async (dispatch) => {
  try {
    dispatch(cmsAction(data));
  } catch (error) {
    logger(error);
  }
};
export const selectProfileData = (state) => state.profile.profileData;
export const selectLanguageData = (state) => state.profile.languageList;
export const selectCountryData = (state) => state.profile.countryList;
export const selectCmsData = (state) => state.profile.cmsList;
export default profileSlice.reducer;
