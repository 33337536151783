import CryptoJS from "crypto-js";
import moment from "moment";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import config from "../config";
import modalNotification from "./notifications";

const getItem = (path, label, key, icon, children) => {
  if (children) {
    return { label, key, icon, children, path };
  }
  return { label, key, icon, path };
};

export const getHeaderData = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToHeader) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToHeader) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};
export const makeValidLink = (data) => {
  let link = `${
    data?.url?.search("http") < 0 ? `http://${data?.url}` : `${data?.url}`
  }`;
  return link;
};
export const phoneNumberField = (e) => {
  let ASCIICode = e.which ? e.which : e.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    e.preventDefault();
  }
};
export const getFooterLink = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToFooter) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToFooter) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const removeSessionStorageToken = () => {
  if (sessionStorage.getItem(`${config.NAME_KEY}:token`)) {
    sessionStorage.setItem(`${config.NAME_KEY}:token`, null);
  }
};

export const setSessionStorageToken = (token) => {
  sessionStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  );
};

export const setSessionStorage = (keyName, formData) => {
  let stringData = JSON.stringify(formData);
  sessionStorage.setItem(
    `${config.NAME_KEY}:${keyName}`,
    CryptoJS.AES.encrypt(stringData, `${config.NAME_KEY}-${keyName}`).toString()
  );
};

export const getSessionStorage = (keyName) => {
  const cipherText = sessionStorage.getItem(`${config.NAME_KEY}:${keyName}`);
  if (cipherText) {
    const bytes = CryptoJS.AES.decrypt(
      cipherText,
      `${config.NAME_KEY}-${keyName}`
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return false;
};

export const removeSessionStorage = (keyName) => {
  if (sessionStorage.getItem(`${config.NAME_KEY}:${keyName}`)) {
    sessionStorage.setItem(`${config.NAME_KEY}:${keyName}`, "");
  }
};

export const removeLocalStorageToken = (navigate) => {
  if (localStorage.getItem(`${config.NAME_KEY}:token`)) {
    localStorage.setItem(`${config.NAME_KEY}:token`, null);
  }
  if (navigate) {
    navigate("/");
  }
};

export const getSessionStorageToken = () => {
  const ciphertext = sessionStorage.getItem(`${config.NAME_KEY}:token`);
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, `${config.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const setLocalStorageToken = (token) => {
  localStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  );
};

export const getLocalStorageToken = () => {
  const token = localStorage.getItem(`${config.NAME_KEY}:token`);
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, `${config.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const getLocalStorageLanguage = () => {
  const language = localStorage.getItem(`${config.NAME_KEY}:language`);
  if (language) {
    return ["en", "hi"].includes(language) ? language : config.DEFAULT_LANGUAGE;
  }
  return config.DEFAULT_LANGUAGE;
};

export const getCompleteUrl = (url) => {
  return url;
};

export function decodeQueryData(data) {
  return JSON.parse(
    `{"${decodeURI(data)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace("?", "")}"}`
  );
}

export const navigateWithParam = (data, navigate, pathname) => {
  const searchParams = new URLSearchParams(data).toString();
  navigate(`${pathname}?${searchParams}`);
};

export function getSortType(data) {
  return data === "ASC" ? "asc" : "desc";
}

export function dateFormatter(params, format) {
  return moment(params)
    .tz(moment.tz.guess())
    .format(format ?? config.DATE_FORMAT);
}

export function filterDateFormatter(param, format) {
  return moment(param).format(format);
}

export function momentDateFormatter(param, format) {
  return moment(param, format);
}

export function readMoreTextShow(data, showMoreText) {
  if ([undefined, null, false].includes(data)) {
    return <></>;
  }
  if (data.length < 50) {
    return <>{data}</>;
  }

  return (
    <p className="mb-0">
      {data.substring(0, 50)}...
      {showMoreText ? (
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            showMoreText({ data });
          }}
        >
          Read More
          {/* {t("common.readMore")} */}
        </Link>
      ) : (
        ""
      )}
    </p>
  );
}

export function PhoneNumber({ countryCode, contactNumber }) {
  if (countryCode && contactNumber) {
    return <>{`${countryCode}-${contactNumber}`}</>;
  }
  return <span className="center">-</span>;
}
export function PhoneNumberCountry({ countryCode, contactNumber }) {
  let code = "";
  if (countryCode !== null) {
    if (countryCode.toString().includes("+")) code = countryCode;
    else code = `+${countryCode}`;
  }
  if (countryCode !== null && contactNumber !== null) {
    return <>{`${code}-${contactNumber}`}</>;
  } else if (countryCode === null && contactNumber !== null) {
    return <>{`${contactNumber}`}</>;
  } else return <span className="center">-</span>;
}
export function otpRegex() {
  let regex = /^[0-9]+$/;
  return regex;
}

export function phoneRegex() {
  let regex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return regex;
}

export function getSideBarData(arr) {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToSidebar) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToSidebar) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
}

export const disabledFutureDate = (current) => {
  return current > moment();
};

export const disabledPastDate = (current) => {
  return moment().add(-1, "days") >= current;
};

export function encoder(code) {
  return window.btoa(code);
}
export function decoder(str) {
  // return window.atob(code);
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return window.atob(str);
  } catch (err) {
    modalNotification({
      type: "error",
    });
    return false;
  }
}

export const encodeSubscriptionJson = (data) => {
  let arr = [];
  Object.keys(data)?.forEach((item) => {
    if (data[item] === true) {
      arr.push(item);
    }
  });
  return arr;
};

export const decodeSubscriptionJson = (data, subscriptionData) => {
  let mainObj = {};
  if (data.length > 0) {
    subscriptionData?.forEach((item) => {
      if (data.includes(item?.name)) {
        mainObj[item?.name] = true;
      } else {
        mainObj[item?.name] = false;
      }
    });
  }

  return mainObj;
};

export const disableStartDateFutureDays = (
  current,
  values,
  dateFormat,
  initialValues
) => {
  let date;
  const end = momentDateFormatter(values.endDate, dateFormat);
  if (values.endDate === "") {
    date = !current.isBefore(moment());
  } else if (initialValues?.startDate || values.startDate === "") {
    date = !(current.isBefore(moment()) && end.isAfter(current));
  } else {
    date = !(current.isBefore(moment()) && end.add(1, "day").isAfter(current));
  }

  return date;
};

export const disableEndDateFutureDays = (current, dateFormat, fromDate) => {
  let date;
  let start;
  if (fromDate === "") {
    start = momentDateFormatter(new Date(), dateFormat);
    // date = !start.isSameOrBefore(current);
  } else {
    start = momentDateFormatter(fromDate, dateFormat);
  }
  date = !(current.isAfter(start) && current.isSameOrBefore(moment()));
  return date;
};

export const allCountryData = (data) => {
  let mainObj = {
    counrty: [],
    label: {},
  };
  data.forEach((item) => {
    if (item?.country_short_code) {
      mainObj.counrty.push(item?.country_short_code);
    }
    if (item?.country_code) {
      mainObj.label[item.country_short_code] = {
        // primary: `${item?.country_name}`,
        primary: `+${item?.country_code}`,
      };
    }
  });
  return mainObj;
};

export const getUniqueListBy = (arr, key1) => {
  return [...new Map(arr.map((item) => [item[key1], item])).values()];
};

export const filterLanguage = (list, name) => {
  if (list?.length > 0) {
    let data = [];
    list.forEach((item) => {
      return Object.keys(item).forEach((key) => {
        if (typeof item?.[key] === "string") {
          if (new RegExp(name, "i").test(item?.[key])) {
            data.push(item);
          }
        }
      });
    });
    data = getUniqueListBy(data, "full_code");
    return data;
  }
};

export const stringToHTML = (str) => {
  return parse(str);
};
export const getCharLeft = (char, maxChar) => {
  if (char && maxChar) return `${maxChar - char.length}/${maxChar}`;
  else return `${maxChar}/${maxChar}`;
};

export const dateValidationForDOB = (param) => {
  return moment().subtract(param, "days");
};

export const momentTimeFormatter = (param) => {
  return moment(param);
};

export const agoDateTime = (addHour, addType, format) => {
  let getCurrentTime = momentTimeFormatter(new Date());
  return getCurrentTime.add(addHour, addType).format(format);
};
