export const text = {
  planName: "Plan Name",
  price: "Original Price",
  quaterly: "Quaterly",
  halfYearly: "Half-Yearly",
  annual: "Annual",
  planType: " Plan Type",
  translation: "Translation",
  recordMeeting: "Record Meetings",
  cloudRecord: "Save recordings to cloud",
  cloudStorage: "Secure cloud storage",
  meetingDuration: "Maximum meeting duration",
  audioVideoConference: "Audio & video conference (HD quality)",
  polls: "Polls",
  raiseHand: "Raise hand",
  breakoutRooms: "Breakout rooms",
  screenSharing: " Screen sharing",
  voiceTranscription: "Voice transcription",
  vandtTranscription: "Voice & text translation",
  liveStream: "Live stream",
  shareVideo: " Share youtube videos",
  attendance: " Track attendance",
  mute: "Mute participants",
  disableCamera: " Disable everyone's camera",
  conferencing: "Video Conferencing",
  limitPlaceholder: "Character limit",
  translationPlaceholder: "Enter Character Limit",
  selectPlaceholder: "Select",
  pricePlaceholder: "Enter Price",
  originalPricePlaceholder: "Enter Original Price",
  planNamePlaceholder: "Enter Plan Name",
  valuePlaceholder: "Enter Value",
  international: "International dial-in phone numbers",
  host: "Host & attend meeting from mobile",
  inConference: "In-conference chat",
  whiteboard: "DaakPad (whiteboard)",
  noise: "Background noise cancellation",
  compatibility: "Compatibility with google & microsoft calendar",
  deviceSupport: "Mobile device support",
  encryption: "End-to-end encryption",
  lobby: "Lobby mode",
  protected: "Password protected meetings",
  spam: "Phishing & spam protection upto 99.9%",
  title: "Manage Subscription",
  add: "Add",
  addPlan: "Add Plan",
  editPlan: "Edit Plan",
  planPrice: "Plan price",
};
