export const text = {
  metaTitle: "Home",
  heading: "About Us",
  subHeading: "about us",
  thinkappsBrings: "Thinkapps brings",
  ideasToLife: "ideas to life",
  solutionsWeOffer: "Solutions We Offer",
  pricing: "Pricing",
  ourTeam: "Our Team",
  contactUs: "Contact Us",
  signIn: "Sign In",
  signUp: " Sign Up",
};
