export const validation = {
  password: "Please enter a Password",
  confirmPassword: "Please Re-enter password",
  passwordField:
    "Password should be atleast 6 to 15 character,\nOne Uppercase, One Lowercase,\nOne Number & special Character",
  email: "Please enter a email ",
  validEmail: "Please enter a valid email address",
  minPhone: "Phone number must be at least 10 digits",
  phone: "Phone number is required",
  countryCode: "Country code is required",
  phoneNumberReq: "Please enter the phone number",
};
