export const text = {
  title: "My Account",
  mobileNumber: "Mobile Number",
  enterNumber: "Enter Mobile Number",
  email: "Email Address",
  countryCode: "Country code",
  firstName: "First Name",
  enterFirstName: "Enter First Name",
  lastName: "Last Name",
  enterLastName: "Enter Last Name",
  companyName: "Company Name",
  companyPlaceholder: "Enter Company Name",
  dob: "Date Of Birth",
  emailprofileLabel: "Email Id",
  phoneLabel: "Phone Number",
  phonePlaceholder: "Please enter phone number",
  finish: "Finish",
};
