export const text = {
  dashboard: "Dashboard",
  completeLoan: "Completed Loan",
  pandingLoan: "Panding Loan",
  totalApplication: "Total Application",
  totalCompleteApp: "Total Completed Application",
  totalRejectedApp: "Total Rejected Application",
  totalLoanDisbursed: "Total Loan Disbursed In Rs",
  pendingApproval: "Pending Approval(Or Loan Eligibility)",
  totalNumberOfAppDisbursed: "Total Number Of Application Disbursed",
};
