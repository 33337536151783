export const text = {
  title: "Update Social Link",
  facebook: "Facebook",
  facebookPlaceholder: "Enter facebook link",
  twitter: "Twitter",
  twitterPlaceholder: "Enter twitter link",
  instagram: "Instagram",
  instagramPlaceholder: "Enter instagram link",
  linkedin: "Linkedin",
  linkedinPlaceholder: "Enter linkedin link",
  submit: "Update Links",
};
