export const text = {
  name: "Name",
  preferred: " Preferred Translation Language",
  country: "Country",
  gender: "Gender",
  saveChanges: "Save Changes",
  editProfile: "Edit My Profile",
  genderPlaceholder: "Select Gender",
  languagePlaceholder: "Select Language",
  title: "My Account",
  mobileNumber: "Mobile Number",
  enterNumber: "Enter Mobile Number",
  email: "Email Address",
  countryCode: "Country code",
  firstName: "First Name",
  enterFirstName: "Enter First Name",
  lastName: "Last Name",
  enterLastName: "Enter Last Name",
  companyName: "Company Name",
  companyPlaceholder: "Enter Company Name",
  dob: "Date Of Birth",
  emailprofileLabel: "Email Id",
  phoneLabel: "Phone Number",
  phonePlaceholder: "Please enter phone number",
  emailPlaceholder: "Please enter email address",
  finish: "Finish",
  changeEmail: "Change Email",
  addPhone: " Add Phone Number",
  addEmail: " Add Email Address",
  temporaryCodeEmail: " We'll send a temporary verification code to this email",
  temporaryCodePhone:
    " We'll send a temporary verification code to this phone number",
  currentEmail: "Your current email is",
  currentPhone: "Your current phone number is",
  sendCode: "Send Verification Code",
  continue: "Continue",
  update: "Update The ",
  new: "New",
  ok: "Ok",
  added: "Added",
  successfull: "Successfull",
  verificationPhone: "Enter verification code sent to your phone number",
  verificationEmail: "Enter verification code sent to your email address",
  newEmailPlaceholder: "Enter New Email Address",
  newPhonePlaceholder: "Enter New Phone Number",
};
