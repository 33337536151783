export const validation = {
  upiId: "UPI ID is required",
  description: "Description is required",
  cardNumber: "Please enter card number",
  cardHolder: "Please enter name",
  expireDate: "Please enter expiry date",
  cvv: "Please enter CVV",
  validName: "Card holder name should be in the alphabets",
  maxCardNo: "Card number must be 14 to 16 digits",
  maxCvv: "CVV number must be 3 to 4 digits",
  validExpire: "Enter valid expire date",
  documentType: "Document Type is required",
};
