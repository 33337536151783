export const text = {
  loginTitle: "Login",
  email: "Email",
  emailPlaceholder: "Enter your email address",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  rememberMe: "Remember Me",
  forgotPasswordLink: "Forgot Password?",
  adminWelcome: "Welcome,",
  adminLoginDescription:
    "Access the Daakia panel using your email and password.",
  submit: "Sign In",
  signin: "Sign-In",
};
