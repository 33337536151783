export const text = {
  description: "Description",
  payUsing: "Pay Using UPI",
  internetBanking: "Internet Banking",
  upiIdPlaceholder: "Enter UPI ID here",
  affirmative: "Affirmative Action",
  certificate:
    "Certificate may be verified with appropriate Government Authorities. Falsification of data would render the user liable to action.",
  benefit:
    " This benefit is available in India as of now and would be extended to weaker sections of various countries subsequently. Please bear with us.",
  sc: "SC / ST",
  obc: "OBC ",
  ews: "EWS",
  general: "General",
  selectRefund: "Select Refund Option",
  addCoin: "Add As Coins To My Profile",
  refundBack: "Refund Back To Payment Source",
  disclaimer: "Disclaimer:",
  payNow: " Pay Now",
  upload: "Upload",
  auto: "Auto-Renewal",
  cardHolder: "Card holder's name",
  cardNumber: "Card number",
  expDate: "Expiration date",
  cvv: "CVV",
  documentType: "Select Document Type",
  coinAvailable: " Coins Available",
  useCoins: " Use Coins",
  saveCard: "Save this card to make your payments easy",
  addCredit: "Add Credit & Debit Cards",
  creditAmount:
    "The credit amount can be used towards your next purchase. In case documents are improper or cannot be validated, credit would not be processed and you will be informed of the same.",
  selectAffirmative:
    "You have selected to avail of our Affirmative Action Plan. If your documents are validated, you would be refunded/given a credit of INR 100 against INR 999 paid to us towards your subscription.  This Plan is voluntary and can be stopped without further  notice.",
  plan: "Plan",
  price: "Subscription Price",
  expOfdate: "Date of Expiration",
  payment: "Total Payment",
  coins: "Coins",
  saveCards: "Saved Cards",
  upi: "UPI",
  creditdebit: " Credit and Debit Cards",
  saveOption: " Saved payment options",
  choosePayment: " Choose Payment Mode",
  paymentSucessfull: "Payment Successfull",
  sentToEmail:
    " Thank You!! for your payment. Payment receipt will be sent to your registered email.",
  yes: "Yes",
  no: "No",
};
