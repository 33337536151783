export const text = {
  title: "Change Password",
  description: "Set a unique password to protect your account.",
  email: "Email Address",
  oldPassword: "Old Password",
  newPassword: " New Password",
  confirmPassword: "Confirm Password",
  currentPlaceholder: "Enter current password",
  newPlaceholder: "Enter New Password",
  confirmPlaceholder: "Confirm New Password",
  passwordContains: "Password contains:",
  capitalLetter: "At least 1 Capital Letter & 1 Small Letter",
  specialCharacterNumber: "At least 1 Special Character & 1 Number.",
  characterLength: "6-15 Character long.",
  submit: " Update Password",
};
