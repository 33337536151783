// import {
//   Home,
//   PaymentMethod,
//   PlanSubscription,
//   PurchasePlan,
//   Translation,
//   UserAccount,
//   UserContactUs,
//   UserDashboard,
//   VideoConferencing,
//   UserCms,
// } from "../../pages";
// import userRoutesMap from "../../routeControl/userRoutes";

export default function route() {
  return [];
}
