export const text = {
  documentType: "Type",
  reason: "Enter Reason",
  typeName: "Type Name",
  deleteDocumentText: "you want to delete this document type?",
  documentTypeTitle: "Document Type",
  contactUsReasonTitle: "Contact Us Reason",
  documentTypePlaceholder: "Enter Document Type",
  addReason: "Add Reason",
  editReason: "Edit Reason",
  activateReasontext: "you want to activate this reason?",
  deactivateReasontext: "you want to deactivate this reason?",
  deleteReasonText: "you want to delete this reason?",
  title: "Discount Request",
  documentUploaded: "Document Uploaded",
  requestDate: "Request Date",
  approvalDate: "	Approval Date",
  rejectionDate: "Rejection Date",
  refund: "Refund",
  rejectionReason: "Reason for rejection",
  discountAmount: "Enter the discount amount",
  writeReason: "Write Reason",
};
