import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Spinner } from "react-bootstrap";
import { routes } from "./routes";
import "./App.css";
// import * as fullPageLoader from "./js/jquery.fullPage.min.js";
// import "antd/dist/antd.min.css";

function RouteLayout({ path }) {
  const element = useRoutes(path);
  return element;
}

// function loadScriptSync(src) {
//   let s = document.createElement("script");
//   s.src = src;
//   s.type = "text/javascript";
//   s.async = false; // <-- this is important
//   document.getElementsByTagName("head")[0].appendChild(s);
// }

// loadScriptSync("%PUBLIC_URL%/assets/js/jquery.fullPage.min.js")

// console.log(window?.$);

// function initFullPage() {
//   // if ($(window).width() > 1199) {
//   let slideIndex2 = 1;
//   let sliding = false;
//   $("#fullpage").fullpage({
//     // ...your cutom code...
//     sectionSelector: ".section",
//     controlArrows: false,
//     // events
//     onLeave(index, nextIndex, direction) {
//       if (index == 3 && !sliding) {
//         if (direction == "down" && slideIndex2 < 4) {
//           sliding = true;
//           $.fn.fullpage.moveSlideRight();
//           return false;
//         } else if (direction == "up" && slideIndex2 > 1) {
//           sliding = true;
//           $.fn.fullpage.moveSlideLeft();
//           return false;
//         }
//       } else if (sliding) {
//         return false;
//       }
//     },
//     afterSlideLoad(anchorLink, index, slideAnchor, slideIndex) {
//       sliding = false;
//     },
//     onSlideLeave(anchorLink, index, slideIndex, direction, nextSlideIndex) {
//       console.log(nextSlideIndex);
//       if (nextSlideIndex == 1) {
//         if (direction == "right") {
//           sliding = true;
//           slideIndex2++;
//         }
//         if (direction == "left") {
//           sliding = true;
//           slideIndex2--;
//         }
//       }
//       if (index == 3) {
//         if (direction == "right") {
//           sliding = true;
//           slideIndex2++;
//         }
//         if (direction == "left") {
//           sliding = true;
//           slideIndex2--;
//         }
//       }
//     },
//   });

//   $("#forAd").on("click", function () {
//     $("#fullpage").fullpage.moveTo($(this).index() + 4);
//   });
//   $("#forAff").on("click", function () {
//     $("#fullpage").fullpage.moveTo($(this).index() + 5);
//   });

//   $("#marketingsales").on("click", function () {
//     $("#fullpage").fullpage.moveTo($(this).index() + 2);
//   });
//   // }
// }

function App() {
  return (
    <>
      <HelmetProvider>
        <Suspense
          fallback={
            <div className="d-flex align-items-center justify-content-center h-100 mainLoader">
              <Spinner variant="light" animation="border" role="status" />
            </div>
          }
        >
          <RouteLayout path={routes()} />
        </Suspense>
      </HelmetProvider>
    </>
  );
}

export default App;
