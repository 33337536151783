export const text = {
  numberLabel: "Sign In with Phone Number",
  loginDescription:
    "Enjoy all the benefits of mobile app on the web plus much more!",
  useEmail: "Use email instead",
  numberPlaceholder: "Please enter your phone number",
  emailLabel: "Sign In with Email",
  useNumber: " Use phone number instead",
  agree: " I agree with",
  termsConditions: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  and: "and",
  next: "Next",
  haventAccount: "Dont have an account?",
  emailPlaceholder: "Please enter your email",
  signUp: "Sign Up",
  verification: "Enter Verification Code✍️",
  verificationdescription: "Please enter verification code sent to your ",
  mobile: " mobile number",
  changeNumber: "Change Number",
  changeEmail: "Change Email Address",
  verificationLabel: "Enter Verification Code",
  valid: " Your Code is valid for",
  time: "00:30",
  sec: "sec.",
  submit: "Submit",
  endUser: "End User Agreement",
};
