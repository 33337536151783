export const text = {
  title: "Transaction History",
  invoice: "Invoice id",
  subscriptionPlan: "Subscription Plan",
  previousAdjusted: "Previous Adjusted",
  amountPaid: "Amount Paid",
  coinsApplied: "Coins Applied",
  subscriptionPrice: "Subscription Price",
  refund: "Refund",
};
